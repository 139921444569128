import React from 'react';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';

import {
  BlogContainer,
  ImageContainer,
  DataContainer,
  Tag,
  TitleContainer,
  SubtitleContainer,
  MoreInfoContainer,
  MoreInfoText,
  BlogTitleTooltip,
  BlogTitleContainer,
} from './blogCard.styles';

import { BlogCardProps } from './blogCard.types';

const BlogCard = ({ blog, renderTooltip }: BlogCardProps) => {
  return (
    <BlogContainer href={`${PATHS.BLOGS}/${transformToSlug(blog.attributes.Title.toLowerCase())}`}>
      <ImageContainer>
        <Tag>Article</Tag>
        {CMSParseImage(blog.attributes.Small_Image.data)}
      </ImageContainer>
      <DataContainer className="blog-data">
        <BlogTitleContainer>
          <TitleContainer className="blog-title">{blog.attributes.Title}</TitleContainer>
          {renderTooltip?.includes(blog.attributes.Title) && (
            <BlogTitleTooltip className="blog-title-tooltip">
              {blog.attributes.Title}
            </BlogTitleTooltip>
          )}
        </BlogTitleContainer>
        <SubtitleContainer>{blog.attributes.Subtitle}</SubtitleContainer>
        <MoreInfoContainer>
          <MoreInfoText>{blog.attributes.Author}</MoreInfoText>
          <MoreInfoText>{blog.attributes.Time_To_Read}</MoreInfoText>
        </MoreInfoContainer>
      </DataContainer>
    </BlogContainer>
  );
};

export default BlogCard;
