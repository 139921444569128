import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Head from '@components/Head';

import { LayoutContainer } from '@components/Layout';
import { WelcomePromoBar } from '@global/data/promobarsData';
import InActionHero from '@sections/InActionHero/InActionHero';
import QuickAccessPanel from '@sections/QuickAccessPanel/QuickAccessPanel';
import { GlobalWrapper } from '@global/styles/grid';
import ArticleList from '@sections/ArticlesList/ArticleList';
import ArticleFilter from '@sections/ArticlesList/components/ArticleFilter/ArticleFilter';
import { InActionContainer, QuickAccessContainer } from './styles';

const InActionIndex = ({ data }: any) => {
  const [filterApplied, setFilterApplied] = useState<string[]>([]);
  const [renderFilterPage, setRenderFilterPage] = useState(false);
  const landingLayoutData = data?.cms?.landing?.data?.attributes?.Layout;
  const quickAccessPanelData = data?.cms?.inActionQuickPanelAccesses?.data;
  const topicsFilter = data?.cms?.blogTopics?.data;
  const rolesFilter = data?.cms?.blogRoles?.data;
  const careerClustersFilter = data?.cms?.blogCareerClusters?.data;
  const heroData = { blogs: data?.cms.heroBlogs.data, webinars: data?.cms.heroWebinars.data };
  const blogs = data?.cms.blogs.data;
  const webinars = data?.cms.webinars.data;

  if (heroData.blogs?.length + heroData.webinars?.length > 3) {
    if (heroData.blogs?.length > 0 && heroData.webinars?.length > 0) {
      if (heroData.blogs?.length > 2) {
        heroData.blogs.pop();
      }
      if (heroData.webinars?.length > 2) {
        heroData.webinars.pop();
        if (heroData.blogs?.length > 1) {
          heroData.webinars.pop();
        }
      }
    }
  }

  const handleBlogsCarrousel = () => {
    const sortedBlogViaPublicationDate = blogs.sort((blog1, blog2) => {
      return new Date(blog1.attributes.Publication_Date) <
        new Date(blog2.attributes.Publication_Date)
        ? 1
        : -1;
    });

    if (blogs.length > 5) {
      return sortedBlogViaPublicationDate.slice(0, 5);
    }
    return sortedBlogViaPublicationDate;
  };

  const handelFilterApplied = (value: string) => {
    if (filterApplied.find((filter) => filter === value)) {
      setFilterApplied(filterApplied.filter((filter) => filter !== value));
    } else {
      setRenderFilterPage(true);
      setFilterApplied([...filterApplied, value]);
    }
  };

  const handleClearFilters = () => {
    setFilterApplied([]);
  };

  return (
    <main>
      <Head
        title="Your College & Career Readiness Journey | Pathful"
        description="Pathful is the complete college and career readiness system that helps students decide what they want to be and how to prepare for life beyond the classroom."
        tag="classroom, career, internship, learning, curriculum, career exploration, discovery, planning, path, work-based learning, career readiness, education outreach, real-world learning, course planner, graduation plans, life skills, digital lessons, virtual lessons, virtual job shadow, job shadow, job shadowing, career videos, career technical education, career placement, K12 internship opportunities"
        url="https://pathful.com/"
        imageUrl="https://pathful.com/pathful_preview.png"
      />
      <LayoutContainer
        promobarConfig={WelcomePromoBar}
        activeLogo={landingLayoutData?.Topbar_Scroll_Logo}
        topbarBorderColor={landingLayoutData?.Topbar_Details_Color}
        topbarMenuId={landingLayoutData?.Topbar_Menu}
        footerMenuId={landingLayoutData?.Footer_Menu}
        floatingTopbar
      >
        {renderFilterPage ? (
          <GlobalWrapper>
            <ArticleFilter
              topicsFilter={topicsFilter}
              rolesFilter={rolesFilter}
              careersFilter={careerClustersFilter}
              blogs={blogs}
              webinars={webinars}
              filterApplied={filterApplied}
              handelFilterApplied={handelFilterApplied}
              handleClearFilters={handleClearFilters}
            />
          </GlobalWrapper>
        ) : (
          <>
            <InActionHero data={heroData} />
            <GlobalWrapper>
              <InActionContainer>
                <QuickAccessContainer>
                  <QuickAccessPanel data={quickAccessPanelData} />
                </QuickAccessContainer>
                <ArticleList
                  topicsFilter={topicsFilter}
                  rolesFilter={rolesFilter}
                  careersFilter={careerClustersFilter}
                  blogs={handleBlogsCarrousel()}
                  webinars={webinars}
                  filterApplied={filterApplied}
                  handelFilterApplied={handelFilterApplied}
                />
              </InActionContainer>
            </GlobalWrapper>
          </>
        )}
      </LayoutContainer>
    </main>
  );
};

export const pageQuery = graphql`
  query ($id: ID!) {
    cms {
      landing(id: $id) {
        data {
          attributes {
            Layout {
              Topbar_Details_Color
              Topbar_Scroll_Logo {
                data {
                  id
                  attributes {
                    url
                  }
                }
              }
              Topbar_Menu
              Footer_Menu
            }
          }
        }
      }
    }
    cms {
      webinars(pagination: { limit: -1 }, sort: "publishedAt") {
        data {
          attributes {
            Title
            Subtitle
            Schedule
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Roles {
              data {
                attributes {
                  Role
                }
              }
            }
            Topics {
              data {
                attributes {
                  Topic
                }
              }
            }
            Career_Clusters {
              data {
                attributes {
                  Career_Cluster
                }
              }
            }
          }
        }
      }
      blogs(pagination: { limit: -1 }) {
        data {
          attributes {
            Author
            Title
            Subtitle
            Time_To_Read
            Publication_Date
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
            Roles {
              data {
                attributes {
                  Role
                }
              }
            }
            Topics {
              data {
                attributes {
                  Topic
                }
              }
            }
            Career_Clusters {
              data {
                attributes {
                  Career_Cluster
                }
              }
            }
          }
        }
      }
      heroWebinars: webinars(filters: { In_Action_Panel: { eq: true } }, pagination: { limit: 3 }) {
        data {
          attributes {
            Title
            Schedule
            Subtitle
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
          }
        }
      }
      heroBlogs: blogs(filters: { In_Action_Panel: { eq: true } }, pagination: { limit: 3 }) {
        data {
          attributes {
            Title
            Subtitle
            Time_To_Read
            Publication_Date
            Small_Image {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
          }
        }
      }
    }
    cms {
      inActionQuickPanelAccesses(pagination: { limit: -1 }) {
        data {
          attributes {
            Quick_Panel_Access_CTA_Link
            Quick_Panel_Access_CTA_Text
            Quick_Panel_Access_Subtitle
            Quick_Panel_Access_Title
            Quick_Panel_Access_Icon {
              data {
                attributes {
                  alternativeText
                  url
                  mime
                  urlJSON
                }
              }
            }
          }
        }
      }
    }
    cms {
      blogCareerClusters {
        data {
          attributes {
            Career_Cluster
          }
        }
      }
    }
    cms {
      blogRoles {
        data {
          attributes {
            Role
          }
        }
      }
    }
    cms {
      blogTopics {
        data {
          attributes {
            Topic
          }
        }
      }
    }
  }
`;

export default InActionIndex;
