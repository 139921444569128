import { COLORS } from '@global/styles/colors';
import styled from 'styled-components';

export const SeparateLine = styled.div`
  margin: 0 5px;
  font-size: 16px;
  line-height: 16px;
  color: ${COLORS.violetRed};
`;

export const WebinarDate = styled.p`
  margin-left: 5px;
  display: flex;
  align-items: flex-start;
  font-size: 16px;
  line-height: 21px;
  font-weight: normal;
  font-family: 'Poppins';
  color: ${COLORS.resolutionBlue};
`;

export const WebinarSchedule = styled.div`
  min-height: 32px;
  padding-bottom: 5px;
  display: flex;
  align-items: flex-end;
  color: ${COLORS.resolutionBlue};
  font-size: 36px;
  line-height: 32px;
  font-family: 'Poppins';
  font-weight: Bold;
  border-bottom: 1px solid #d5d5d5;
`;

export const WebinarContainer = styled.a`
  width: inherit;
  box-sizing: border-box;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    .webinar-data {
      background-color: #e2e8f0;
    }
  }
`;

export const Tag = styled.div`
  width: 110px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: ${COLORS.violetRed};
  border-radius: 0 8px 0 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  color: ${COLORS.white};
`;
export const DataContainer = styled.div`
  padding: 16px 16px 13px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 0 0 8px 8px;
  border: 1px solid #d5d5d5;
  border-top: none;
`;

export const TitleContainer = styled.div`
  min-height: 52px;
  font-family: 'Poppins';
  font-size: 20px;
  line-height: 26px;
  font-weight: bold;
  color: #000000;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SubtitleContainer = styled.div`
  min-height: 60px;
  font-family: 'Poppins';
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.resolutionBlue};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 140px;
  position: relative;
  border-radius: 8px 8px 0 0;

  img {
    height: 140px !important;
    border-radius: 8px 8px 0 0;
    object-fit: cover;
    object-position: left;
  }
`;

export const WebinarTitleTooltip = styled.div`
  padding: 10px;
  bottom: 54%;
  left: 10%;
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  text-decoration: none;
  background-color: ${COLORS.white};
`;

export const WebinarTitleContainer = styled.div`
  min-height: 52px;
  padding-top: 10px;
  position: relative;

  &:hover {
    .webinar-title-tooltip {
      display: flex;
    }
  }
`;
