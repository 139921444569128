import React, { useEffect, useState } from 'react';
import { GlobalWrapper } from '@global/styles/grid';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { WebinarSchedule } from '@sections/WebinarIndexHero/webinarIndexHero.styles';
import { PATHS } from '@global/constants/urls';
import {
  ArticlesContainer,
  BlogData,
  BlogPublicationDate,
  ArticleSubtitle,
  BlogTimeToRead,
  ArticleTitle,
  Container,
  ImageContainer,
  SubtitleContainer,
  Tag,
  TitleContainer,
  WebinarDate,
  ArticleWrapper,
  DataContainerArticle,
  ArticleTitleContainer,
  ArticleTitleTooltip,
} from './inActionHero.styles';
import { ArticleType, InActionHeroProps } from './inActionHero.types';

const InActionHero = ({ data }: InActionHeroProps) => {
  const [renderTooltip, setRenderTooltip] = useState<string[]>([]);

  const articles = [
    data.blogs.map((blog) => ({ ...blog, type: 'blog' })),
    data.webinars.map((webinar) => ({ ...webinar, type: 'webinar' })),
  ];
  const handleSchedule = (schedule: string) => {
    const scheduleDate = new Date(schedule);
    const scheduleHours = scheduleDate.getHours();
    const scheduleMinutes = scheduleDate.getMinutes();
    if (!Number.isNaN(scheduleHours) && !Number.isNaN(scheduleMinutes)) {
      return (
        <>
          {scheduleDate.getDate()}
          <WebinarDate>
            {scheduleDate
              .toLocaleString('default', {
                month: 'short',
              })
              .toUpperCase()}
          </WebinarDate>
        </>
      );
    }
    return <WebinarDate>{schedule}</WebinarDate>;
  };

  useEffect(() => {
    const isEllipsisActive = (element) => {
      return element.offsetHeight + 2 < element.scrollHeight;
    };

    const articlesTitle = document.querySelectorAll('.article-title');
    const renderTitleTooltip: string[] = [];
    articlesTitle.forEach((el) => {
      if (isEllipsisActive(el)) {
        renderTitleTooltip.push(el.innerText);
      }
    });
    setRenderTooltip(renderTitleTooltip);
  }, []);

  return (
    <Container>
      <GlobalWrapper>
        <TitleContainer>Resource Center</TitleContainer>
        <SubtitleContainer>
          Unlock the full potential of Pathful with our extensive library of user resources.
        </SubtitleContainer>
        <ArticlesContainer>
          {articles
            .flat()
            .slice(0, 3)
            .map((article, index) =>
              article.type === 'blog' ? (
                <ArticleWrapper
                  isFirstArticle={index === 0}
                  href={`/blog/${transformToSlug(article.attributes.Title.toLowerCase())}`}
                >
                  <DataContainerArticle isFirstArticle={index === 0} className="data-container">
                    {index === 0 && (
                      <Tag isFirstArticle type={ArticleType.Blog} position="relative">
                        Article
                      </Tag>
                    )}
                    <ArticleTitleContainer>
                      <ArticleTitle className="article-title" isFirstArticle={index === 0}>
                        {article.attributes.Title}
                      </ArticleTitle>
                      {renderTooltip.includes(article.attributes.Title) && (
                        <ArticleTitleTooltip className="article-title-tooltip">
                          {article.attributes.Title}
                        </ArticleTitleTooltip>
                      )}
                    </ArticleTitleContainer>
                    {index === 0 && (
                      <ArticleSubtitle>{article.attributes.Subtitle}</ArticleSubtitle>
                    )}
                    <BlogData>
                      <BlogPublicationDate>
                        {article.attributes.Publication_Date.split(' ').map((date, index) =>
                          index === 0 ? `${date.slice(0, 3)} ` : `${date} `,
                        )}
                      </BlogPublicationDate>
                      {index === 0 && (
                        <BlogTimeToRead>{article.attributes.Time_To_Read}</BlogTimeToRead>
                      )}
                    </BlogData>
                  </DataContainerArticle>
                  <ImageContainer isFirstArticle={index === 0}>
                    {CMSParseImage(article.attributes.Small_Image)}
                    {index !== 0 && (
                      <Tag isFirstArticle={false} type={ArticleType.Blog}>
                        Article
                      </Tag>
                    )}
                  </ImageContainer>
                </ArticleWrapper>
              ) : (
                <ArticleWrapper
                  isFirstArticle={index === 0}
                  href={`${PATHS.WEBINAR}/${transformToSlug(
                    article.attributes.Title.toLowerCase(),
                  )}`}
                >
                  <DataContainerArticle isFirstArticle={index === 0} className="data-container">
                    {index === 0 && (
                      <Tag isFirstArticle type={ArticleType.Webinar} position="relative">
                        Training
                      </Tag>
                    )}
                    <WebinarSchedule>{handleSchedule(article.attributes.Schedule)}</WebinarSchedule>
                    <ArticleTitleContainer>
                      <ArticleTitle className="article-title" isFirstArticle={index === 0}>
                        {article.attributes.Title}
                      </ArticleTitle>
                      {renderTooltip.includes(article.attributes.Title) && (
                        <ArticleTitleTooltip className="article-title-tooltip">
                          {article.attributes.Title}
                        </ArticleTitleTooltip>
                      )}
                    </ArticleTitleContainer>
                    {index === 0 && (
                      <ArticleSubtitle>{article.attributes.Subtitle}</ArticleSubtitle>
                    )}
                  </DataContainerArticle>
                  <ImageContainer isFirstArticle={index === 0}>
                    {CMSParseImage(article.attributes.Small_Image)}
                    {index !== 0 && (
                      <Tag isFirstArticle={false} type={ArticleType.Webinar}>
                        Training
                      </Tag>
                    )}
                  </ImageContainer>
                </ArticleWrapper>
              ),
            )}
        </ArticlesContainer>
      </GlobalWrapper>
    </Container>
  );
};

export default InActionHero;
