import React from 'react';
import { CMSParseImage } from '@components/StrapiComponents/ImageParser';
import { transformToSlug } from 'helpers/slugHelper';
import { PATHS } from '@global/constants/urls';
import {
  ImageContainer,
  DataContainer,
  Tag,
  TitleContainer,
  SubtitleContainer,
  WebinarDate,
  SeparateLine,
  WebinarSchedule,
  WebinarContainer,
  WebinarTitleContainer,
  WebinarTitleTooltip,
} from './webinarCard.styles';
import { WebinarCardProps } from './webinarCard.types';

const WebinarCard = ({ webinar, renderTooltip }: WebinarCardProps) => {
  const handleSchedule = (schedule: string) => {
    const scheduleDate = new Date(schedule);
    const scheduleHours = scheduleDate.getHours();
    const scheduleMinutes = scheduleDate.getMinutes();
    const scheduleWeekDate = scheduleDate.toLocaleString('en-us', { weekday: 'long' });

    if (!Number.isNaN(scheduleHours) && !Number.isNaN(scheduleMinutes)) {
      return (
        <>
          {scheduleDate.getDate()}
          <WebinarDate>
            {scheduleDate
              .toLocaleString('default', {
                month: 'short',
              })
              .toUpperCase()}
            <SeparateLine>|</SeparateLine>
            {scheduleWeekDate}
            <SeparateLine>|</SeparateLine>
            {scheduleHours > 10 ? scheduleHours : `0${scheduleHours}`}:
            {scheduleMinutes > 10 ? scheduleMinutes : `0${scheduleMinutes}`}
          </WebinarDate>
        </>
      );
    }
    return <WebinarDate>{schedule}</WebinarDate>;
  };

  return (
    <WebinarContainer
      key={webinar.attributes.Title}
      href={`${PATHS.WEBINAR}/${transformToSlug(webinar.attributes.Title.toLowerCase())}`}
    >
      <ImageContainer>
        <Tag>Training</Tag>
        {CMSParseImage(webinar.attributes.Small_Image.data)}
      </ImageContainer>
      <DataContainer className="webinar-data">
        <WebinarSchedule>{handleSchedule(webinar.attributes.Schedule)}</WebinarSchedule>
        <WebinarTitleContainer>
          <TitleContainer className="webinar-title">{webinar.attributes.Title}</TitleContainer>
          {renderTooltip?.includes(webinar.attributes.Title) && (
            <WebinarTitleTooltip className="webinar-title-tooltip">
              {webinar.attributes.Title}
            </WebinarTitleTooltip>
          )}
        </WebinarTitleContainer>
        <SubtitleContainer>{webinar.attributes.Subtitle}</SubtitleContainer>
      </DataContainer>
    </WebinarContainer>
  );
};

export default WebinarCard;
