import { Image } from '@global/types';

export interface InActionHeroProps {
  data: {
    webinars: SpotlightWebinarProps[];
    blogs: SpotlightBlogsProps[];
  };
}
export interface SpotlightBlogsProps {
  attributes: {
    Title: string;
    Publication_Date: string;
    Small_Image: Image;
    Time_To_Read: string;
    Subtitle: string;
  };
}

export interface SpotlightWebinarProps {
  attributes: {
    Title: string;
    Schedule: string;
    Small_Image: Image;
  };
}

export enum ArticleType {
  Blog,
  Webinar,
}
